// Dependencies
import 'owl.carousel'

// Parts
import './parts/slide-equipe'
import './parts/slide-unidades'
import './parts/slide-blog'
import './parts/links-contato'
import './parts/menu'
// import './parts/counter'


