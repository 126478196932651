$(window).on('scroll',function() {
    var windscroll = $(window).scrollTop();
    if (windscroll >= 100) {
        $('.l-header').addClass('menu-fixed');

    } else {
       $('.l-header').removeClass('menu-fixed');
    }
});

// $('.submenu').on('click',function(e){
//     e.preventDefault();
//     $('.submenu ul').stop().slideUp();
//     // $('.submenu').removeClass('arrow-position');
//     $(this).find('ul').stop().slideToggle();
//     $(this).toggleClass('arrow-position');
// });

$('.icon-menu-mobile').on('click',function(e){
    e.preventDefault();
    $('.l-header').addClass('menu-opened');
    $('.l-header nav').stop().slideDown();
});

$('.icon-menu-mobile-close').on('click',function(e){
    e.preventDefault();
    $('.l-header').removeClass('menu-opened');
    $('.l-header nav').stop().slideUp();
});