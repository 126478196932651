var $slideBlog = $('.slide-blog')
$slideBlog.owlCarousel({
    stagePadding: 51,
    loop:false,
    nav: true,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
	        margin: 16,

        },
        560:{
            items:2,
	        margin: 16,

        },
        1024:{
            items:3,
	        margin: 25,

        }
    }
})