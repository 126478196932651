var $slideUnidades = $('.slide-unidades')
$slideUnidades.owlCarousel({
    loop:false,
    nav: true,
	margin: 16,
    responsiveClass:true,
    responsive:{
        0:{
            items:1,
            stagePadding: 30,
        },
        560:{
            items:2,
            tagePadding: 30,
        },
        1024:{
            items:2,
            stagePadding: 70,
        }
    }
})


