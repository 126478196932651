var $slideEquipe = $('.slide-equipe')
$slideEquipe.owlCarousel({
    stagePadding: 50,
    loop:false,
    responsiveClass:true,
    responsive:{
        0:{
            items:2,
            nav:true,
            margin: 24,

        },
        560:{
            items:3,
            nav:true,
            margin: 32,

        },
        1024:{
            items:5,
            nav:true,
            margin: 32,

        }
    }
})


